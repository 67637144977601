import React from "react"
import Layout from "../components/layout.js"

const Index = () => {
  return (
    <Layout pageTitle="This is not a blog.">
      {/* <h1 className="main__page-title">This is not a blog.</h1> */}
      <div>
        <p>Well, not exactly.</p>
        <p>This site has examples of personal and professional projects, art and other 
          creative works. I've also included a section where I can wax on about life 
          and music, (see what I did there.) That's the part that might get a little 
          bloggy.</p>
        <p>I'm not a visionary. I'm not a rock star in my field. I am a passionate and 
          creative individual who loves to learn and make things. I like to describe 
          myself as a serial hobbyist. Art and nature inspire me. And I cannot function 
          without music.</p>
        <p>More than anything, I am inspired by the people in my life. I love talking 
          to people. I love sharing stories and learning from others' experiences.</p>
      </div>
    </Layout>
  )
}

export default Index